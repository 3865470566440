import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import PrivateRoute from './common/PrivateRoute';
import PageLoader from './common/components/PageLoader';
import SuccessfulSignUp from './pages/Login/SuccessfulSignUp';
import envConsts from './common/envConsts';

// Lazy-loaded components
const Home = React.lazy(() => import('./pages/Home'));
const Login = React.lazy(() => import('./pages/Login'));
const Register = React.lazy(() => import('./pages/Login/Register'));
const Forgot = React.lazy(() => import('./pages/Login/Forgot'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Account = React.lazy(() => import('./pages/Account'));
const Setup = React.lazy(() => import('./pages/Setup'));
const Gallery = React.lazy(() => import('./pages/Gallery'));
const Audience = React.lazy(() => import('./pages/Audience'));
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('./pages/TermsAndConditions'));
const Deactivated = React.lazy(() => import('./pages/Deactivated'));
const EmailGather = React.lazy(() => import('./pages/EmailGather'));
const Pricing = React.lazy(() => import('./pages/Pricing'));
const InstagramAuth = React.lazy(() => import('./pages/Setup/ConnectSocialAccount/InstagramAuth'));
const InstagramReAuth = React.lazy(() => import('./pages/Account/components/InstagramReAuth'));

const EmailConfirmedPage = React.lazy(() => import('./pages/Login/EmailConfirmedPage'));

function RoutesComponent() {
  console.log(envConsts);
  return (
    <Suspense fallback={<PageLoader loading />}>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/success_signup" element={<SuccessfulSignUp />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/deactivated" element={<Deactivated />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/email-confirm" element={<EmailConfirmedPage />} />
        <Route
          path="/connect/:sourceId/:sourceName"
          element={
            <GoogleReCaptchaProvider reCaptchaKey={envConsts.VITE_RECAPTCHA_KEY} useEnterprise>
              <EmailGather />
            </GoogleReCaptchaProvider>
          }
        />

        {/* Private Routes */}
        <Route
          path="/pricing"
          element={
            <PrivateRoute>
              <Pricing />
            </PrivateRoute>
          }
        />
        <Route
          path="/contact"
          element={
            <PrivateRoute>
              <Contact />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/account/:tab?"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        />
        <Route
          path="/setup/instagram-business-auth/:additional?"
          element={
            <PrivateRoute>
              <InstagramAuth tokenType="instagramBusiness" />
            </PrivateRoute>
          }
        />
        <Route
          path="/setup/instagram-business-reauth"
          element={
            <PrivateRoute>
              <InstagramReAuth />
            </PrivateRoute>
          }
        />
        <Route
          path="/setup/*"
          element={
            <PrivateRoute>
              <Setup />
            </PrivateRoute>
          }
        />
        <Route
          path="/gallery"
          element={
            <PrivateRoute>
              <Gallery />
            </PrivateRoute>
          }
        />
        <Route
          path="/audience"
          element={
            <PrivateRoute>
              <Audience />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default RoutesComponent;
